import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { CurrencyCustom } from './currency.pipe';


@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        CurrencyCustom,
    ],
    exports: [
        CurrencyCustom,
    ],
    providers: [CurrencyPipe],
})
export class PipesModule { }
