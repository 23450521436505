import { Injectable } from '@angular/core';
import { ModeOrderService } from './mode-order.service';

@Injectable({
  providedIn: 'root'
})
export class AdditionalService {

    constructor(
      public modeService: ModeOrderService
    ) { }

    getAddedItemsDetails(aditional: any): any[] {
      // console.log("adicional service: ",aditional)
        let addedItems: any[] = [];

        if (aditional && aditional.aditionalDetail) {
          aditional.aditionalDetail.forEach((item: any) => {
            // Si el item tiene hasBeenAdded === true, lo agregamos
            if (item.hasBeenAdded) {
              let price = item.price;  // Precio por defecto
              if (item.aditionalDetailPrice) {
                const storePrice = item.aditionalDetailPrice.find((priceDetail: any) => priceDetail.store.id === this.modeService?.currentModeOrderValue?.storeSelected?.id);
                if (storePrice) {
                  price = storePrice.price;
                }
              }

              addedItems.push({
                code: item.material.code,
                name: item.material.name,
                price: price,
                quantity: item.quantity,
                required: item.required
              });
            }

            // Verificar si el item tiene un material con aditionalDetail anidado
            if (item.material && item.material.aditional && item.material.aditional.aditionalDetail) {
              // Llamada recursiva para seguir bajando en niveles
              const nestedItems = this.getAddedItemsDetails(item.material.aditional);
              addedItems = addedItems.concat(nestedItems); // Agregar los elementos del nivel más profundo
            }
          });
        }

        return addedItems;
    }
}
