import { Subscription } from 'rxjs';
import { Pipe, PipeTransform, OnDestroy } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { CompanyService } from '../services/global/company.service';

@Pipe({
  name: 'currencyCustom'
})
export class CurrencyCustom implements PipeTransform, OnDestroy {

    money: string = '';
    obs: Subscription[] = []

    constructor(private currencyPipe: CurrencyPipe, private companyService: CompanyService) {
        this.obs.push(
            this.companyService.currenCompany.subscribe(res => {
                this.money = res == null ? '' : res.money
            })
        )
    }

    transform(value: any): string {
        const currencySymbol: string = this.money ?? '';
        return this.currencyPipe.transform(value, currencySymbol + ' ') || '';
    }
    
    ngOnDestroy() {
        this.obs.forEach(element => {
        element.unsubscribe()
        });
    }
}