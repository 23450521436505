import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbCollapseModule, NgbNavModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

// scroll package
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '../pipes/pipes.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { MatSliderModule } from '@angular/material/slider';

// component
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';

import { NewsCarouselComponent } from './news-carousel/news-carousel.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { RequestNotificationComponent } from './request-notification/request-notification.component';
import { SidemenuComponent } from './sidemenu/sidemenu.component';
import { DialogCustomComponent } from './dialog-custom/dialog-custom.component';
import { ControlsComponent } from './controls/controls.component';
import { OpenPayPopupComponent } from './open-pay-popup/open-pay-popup.component';
import { LoadPayComponent } from './load-pay/load-pay.component';
import { Controls2ComponentComponent } from './controls2/controls2-component/controls2-component.component';

//
import { LanguageService } from '../services/global/language.service';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    NewsCarouselComponent,
    BreadcrumbComponent,
    RequestNotificationComponent,
    SidemenuComponent,
    DialogCustomComponent,
    ControlsComponent,
    Controls2ComponentComponent,
    OpenPayPopupComponent,
    LoadPayComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbCollapseModule,
    NgbNavModule,
    NgbDropdownModule,
    FormsModule,
    TranslateModule,
    ReactiveFormsModule,
    SlickCarouselModule,
    ScrollToModule.forRoot(),
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatCardModule,
    PipesModule
  ],
  providers: [LanguageService],
  exports: [
    //modules
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatSliderModule,


    HeaderComponent,
    FooterComponent,
    NewsCarouselComponent,
    BreadcrumbComponent,
    RequestNotificationComponent,
    SidemenuComponent,
    ControlsComponent,
    Controls2ComponentComponent,
  ]
})
export class SharedModule { }
